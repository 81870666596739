<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-description"
                label-cols-lg="2"
                :label="$t('COMMON.DESCRIPTION')"
                label-for="field-description-input"
              >
                <b-form-textarea
                  id="field-description-input"
                  v-model="dto.description"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                label-cols-lg="2"
                :label="$t('DATA_PACKAGES.PREVIEW_IMAGE')"
              >
                <b-form-file
                  v-if="dto.image === null"
                  v-model="image"
                  accept="image/jpeg, image/png"
                ></b-form-file>
                <b-input-group v-else>
                  <b-input
                    :state="true"
                    :value="dto.image"
                    :disabled="true"
                  ></b-input>
                  <b-input-group-append>
                    <b-button
                      size="sm"
                      variant="primary"
                      @click="openInNewTab(dto.image)"
                      >{{ $t("COMMON.DOWNLOAD") }}</b-button
                    >
                    <b-button
                      size="sm"
                      variant="secondary"
                      @click="dto.image = null"
                      >{{ $t("COMMON.REPLACE") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group label-cols-lg="2" :label="$t('COMMON.TYPE')">
                <b-form-select
                  :options="typeArray"
                  v-model="dto.modelTypeId"
                ></b-form-select>
              </b-form-group>

              <b-form-group label-cols-lg="2" :label="$t('COMMON.AUTHOR')">
                <CompactSuggestion
                  v-model="dto.authorId"
                  ref="authorSuggestionRef"
                  suggestion-processor="teamSuggestionProcessor"
                ></CompactSuggestion>
              </b-form-group>

              <b-form-group label-cols-lg="2" :label="$t('COMMON.PARAMETERS')">
                <MultiSuggestion
                  suggestion-processor="parameterSuggestionProcessor"
                  :element-name="$t('DEVICE.PARAMETER')"
                  :elements-name="$t('COMMON.PARAMETERS')"
                  :selected-elements="dto.parameters"
                  :addErrorNotification="true"
                  @on-element-added="addParameter"
                  @on-remove-element="removeParameter"
                >
                </MultiSuggestion>
              </b-form-group>
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CompactSuggestion from "../component/CompactSuggestion";
import ApiService from "../../core/services/api.service";
import MultiSuggestion from "../component/MultiSuggestion";

export default {
  name: "ml-model",
  components: { MultiSuggestion, CompactSuggestion },
  data() {
    return {
      save: {
        resource: "/api/ml-model/save",
        requestType: "POST",
        requestParams: {},
      },

      image: null,

      modelTypeRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.MLModelType",
        requestType: "GET",
        requestParams: {},
      },

      typeArray: [],

      dto: {
        id: null,
        name: null,
        description: null,
        modelTypeId: null,
        image: null,
        authorId: null,
        parameters: [],
      },
      createNewStr: this.$t("ML_MODELS.CREATE_NEW_MODEL"),
      editStr: this.$t("ML_MODELS.EDIT_MODEL"),

      showOverlay: true,
    };
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.ML_MODELS"), route: "/ml-models" },
      { title: title },
    ]);
  },

  created: async function () {
    this.loadModelTypes();
    if (this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/ml-model/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => (this.dto = response))
        .catch(this.onError);
      this.$refs.authorSuggestionRef.setText(this.dto.authorName);
    }

    this.showOverlay = false;
  },

  methods: {
    addParameter: function (param) {
      this.dto.parameters.push(param);
    },

    removeParameter: function (index, param) {
      this.dto.parameters.splice(index, 1);
    },

    onSaveClicked: async function () {
      const request = this.save;
      request.dto = this.dto;
      this.showOverlay = true;

      if (this.image != null) {
        const fmData = new FormData();
        fmData.append("image", this.image);
        let result = await ApiService.post("/api/resource", fmData);
        this.dto.image = result.data;
      }

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError)
        .finally(() => {
          this.showOverlay = false;
        });
    },

    openInNewTab(url) {
      window.open(url, "_blank");
    },

    loadModelTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.modelTypeRequest)
        .then((response) => {
          for (const value of response) {
            this.typeArray.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
